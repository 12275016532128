import React from "react";
import { Container } from "@mui/system";
import { Box, Card, Grid } from "@mui/material";
import { getEnv } from "../theme/setThemeColor";

const LandingPageBuildSecurity = () => {
  return (
    <Grid xs={12} className="sectionBreake ">
      <Container maxWidth="lg">
        <div className="landing-bg_main_font" style={{ textAlign: "center" }}>
          Built With Security In Mind
        </div>
        <div className="landingPageSubHeading" style={{ textAlign: "center" }}>
          We provide services that are safe, secure, and compliant.
        </div>

        <Grid container xs={12} sx={{ mt: 5 }}>
          <Grid md={12}>
            <Card
              sx={{
                width: "90%",
                mx: 3,
                my: 2,
                py: 3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                "&:hover": {
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: "20px",
                },
              }}
            >
              <div className="landing-bg_main_font">Safe</div>
              <div className="landing-bg_para">
                We ensure you get a good night’s sleep with your money staying
                with large and highly stable banks in India.
              </div>
            </Card>
            <Card
              sx={{
                width: "90%",
                mx: 3,
                my: 2,
                py: 3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                "&:hover": {
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: "20px",
                },
              }}
            >
              <div className="landing-bg_main_font">Secure</div>
              <div className="landing-bg_para">
                Enjoy secure access to your account with 2-factor authentication
                and TLS/SSL encryption of your data. .
              </div>
            </Card>
            <Card
              sx={{
                width: "90%",
                mx: 3,
                my: 2,
                py: 3,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                "&:hover": {
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: "20px",
                },
              }}
            >
              <div className="landing-bg_main_font">Compliant</div>
              <div className="landing-bg_para">
                {getEnv()} complies with the same set of strict security
                standards as traditional banks in India follow.
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LandingPageBuildSecurity;
