import React, { useState } from "react";
import { Modal, Box, Button, Typography, Backdrop } from "@mui/material";
import * as XLSX from "xlsx";
import { primaryColor, secondaryColor } from "../theme/setThemeColor";
import { excel2Json } from "../utils/exportToExcel";
import { postJsonData } from "../network/ApiController";
import ApiEndpoints from "../network/ApiEndPoints";
import { apiErrorToast, okSuccessToast } from "../utils/ToastUtil";

// Modal styling
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExcelBulkUpload = ({ variant, py, mt, username }) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Function to handle file upload and API call
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      excel2Json(file, setLoading)
        .then((data) => {
          setExcelData(data);
          console.log("Excel data:", data);

          // Call API with the Excel JSON data
          postJsonData(
            ApiEndpoints.ADMIN_UPLOAD_OLD_BENE, // Replace with your actual API endpoint
            { data }, // Sending the Excel JSON as payload
            setLoading, // Progress indicator
            (response) => {
              okSuccessToast(data.message);

              console.log("API response:", response);
              alert("File uploaded and data submitted successfully!");
            },
            (error) => {
              console.error("API error:", error);
              apiErrorToast(error);
            }
          );
        })
        .catch((error) => {
          console.error("Error reading Excel file:", error);
        });
    }
  };

  return (
    <div>
      <Button
        variant={variant || "contained"}
        sx={{
          fontSize: "10px",
          marginLeft: variant ? "" : "5px",
          background: variant ? "" : secondaryColor(),
          py: py && 0.3,
          mt: mt && 1,
          color: "#fff",
        }}
        onClick={handleOpen}
        className="otp-hover-purple"
      >
        Upload Excel
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading && (
            <Backdrop
              sx={{
                // color: "#fff",
                zIndex: "100000",
                height: "100%",
                width: "100%",
                backgroundColor: "#ffffff10",
                backdropFilter: "blur(3px)",
              }}
              open={true}
            >
              <div class="circle-blue"></div>
              <h5 style={{ position: "absolute", left: "45%", top: "60%" }}>
                Please wait request processing
              </h5>
            </Backdrop>
          )}
          <Typography variant="h6" component="h2">
            Upload an Excel File
          </Typography>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            style={{ marginTop: "20px" }}
          />

          <Button
            variant="contained"
            onClick={() => {
              handleFileUpload();
            }}
            sx={{ marginTop: "20px", mr: 2 }}
          >
            Upload
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginTop: "20px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ExcelBulkUpload;
