// import jsPDF from "jspdf";
import XLSX from "xlsx";
import jsPDF from "jspdf";

export const json2Csv = (fileName, data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  // console.log(worksheet);
  //   const csv = XLSX.utils.sheet_to_csv(worksheet);
  //console.log(csv);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const writingOptions = {
    bookType: "csv",
  };
  XLSX.writeFile(workbook, `${fileName}.csv`, writingOptions);
};

export const json2Pdf = (fileName, data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  console.log(worksheet);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   const writingOptions = {
  //     bookType: "html",
  //   };
  //   const html = XLSX.utils.sheet_to_txt(worksheet);
  //   const doc = new jsPDF();
  //   doc.text(data, 10, 10);
  //   doc.save("html_test.pdf");
  //XLSX.writeFile(workbook, `${fileName}.pdf`);
};

export const json2Excel = (fileName, data) => {
  if (data && Array.isArray(data)) {
    for (let i in data) {
      const obj = data[i];
      if (obj) {
        for (let k in obj) {
          if (!isNaN(obj[k])) {
            obj[k] = parseFloat(obj[k]);
          }
        }
      }
    }
  }
  const worksheet = XLSX.utils.json_to_sheet(data);
  //console.log(worksheet);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

// file read . . . .
export const readFileToJsonPromise = (e) => {
  return new Promise((resolve, reject) => {
    try {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const wsname = workbook.SheetNames[0];
      const ws = workbook.Sheets[wsname];
      const jsonData = XLSX.utils.sheet_to_json(ws);
      console.log("Data>>>", jsonData);
      resolve(jsonData); // Resolve the promise with the JSON data
    } catch (error) {
      reject(error); // Reject the promise if an error occurs
    }
  });
};

export const excel2Json = (excelFile, setLoading) => {
  return new Promise((resolve, reject) => {
    if (excelFile) {
      if (setLoading) setLoading(true);
      const reader = new FileReader();
      reader.onload = function (e) {
        readFileToJsonPromise(e)
          .then((result) => {
            if (setLoading) setLoading(false);
            console.log("Progress finished=>", result);
            resolve(result); // Resolve the outer promise with the result
          })
          .catch((error) => {
            if (setLoading) setLoading(false);
            console.error(error);
            reject(error); // Reject if there's an error in the conversion
          });
      };
      reader.onerror = (error) => {
        if (setLoading) setLoading(false);
        console.error("File reading error:", error);
        reject(error); // Reject if there's a file reading error
      };
      reader.readAsArrayBuffer(excelFile);
    } else {
      reject(new Error("No file provided"));
    }
  });
};

export const generatePdf = (jsonData) => {
  const doc = new jsPDF();

  // add text to PDF
  doc.text(`Hello ${jsonData.name}`, 10, 10);

  doc.save("my-document.pdf");
};
